import React from 'react';
import {
  ExtensionSlot,
} from "piral-core";

export default {
  Layout: (props) => (
    <div style={{ display: "table", padding: 20, height: '100%' }}>
      <div style={{ display: "table-row", height: '100%' }}>
        <div style={{ display: "table-cell", width: 200, height: '100%' }}> <ExtensionSlot name="east" params={props} /></div>
        <div style={{ display: "table-cell", width: '100%', height: '100%' }}> <ExtensionSlot name="west" params={props} /></div>
      </div>
    </div>
  ),

}
export default class MappingManager {
  constructor({ configStore }) {
    this.configStore = configStore;
    this.mappings = {};
    this.#parseMappings();
  }

  async executeMappingBeforeLoading({ data, callerCmp, callerSource, opts }) {
    const mapping = this.getMapping({ callerCmp, callerSource });
    if (!mapping) {
      return data;
    }
    return await mapping(data, opts);
  }

  async executeMappingAfterLoading({ data, callerCmp, callerSource, opts }) {
    const mapping = this.getMapping({ callerCmp, callerSource });
    if (!mapping) {
      return data;
    }
    return await mapping(data, opts);
  }

  getMapping({ callerCmp, callerSource }) {
    if (this.mappings[callerCmp] && this.mappings[callerCmp][callerSource]) {
      return this.mappings[callerCmp][callerSource];
    }
    return null;
  }

  #parseMappings() {
    if (!this.configStore) {
      return;
    }
    const config = this.configStore.getConfig();

    Object.keys(config.components).forEach(cmpID => {
      const cmp = config.components[cmpID];
      const componentMappings = cmp?.data?.mappings;
      if (componentMappings) {
        Object.keys(componentMappings).forEach(sourceID => {
          const oneMapping = componentMappings[sourceID];
          if (!this.mappings[cmpID]) {
            this.mappings[cmpID] = {}
          }
          // TODO create actual callable function for mapping here(?)
          this.mappings[cmpID][sourceID] = { ...oneMapping };
        });
      }
    })
  }

}
import React from 'react';
import {
  ExtensionSlot,
} from "piral-core";

export default {
  Layout: (props) => (
    <div style={{ height: '100%' }}><ExtensionSlot name="default" params={props} /></div>
  ),

}
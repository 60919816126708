import React, { useState, useEffect } from "react";

import { createInstance, Piral, createStandardApi } from 'piral';
import errors from './layouts/errors';
import parseLayout from "./layouts/parseLayout";
import EventEmitter from "events";
import FunctionManager from "./FunctionManager";
import { getSubdomain } from "./EnvHelper";
import ExtensionComponent from "./ExtensionComponent";
import { Provider } from "react-redux";
import { isEmpty } from "lodash";


export default ({ data, layout, dataMgr, functionMgr, monitoring }) => {

  // example usage: https://piral.olive-team.com/preview?componentID=%40olive%2Fmodeller.olive-modeller&props=%7B%22text%22%3A%20%22test123%22%7D

  const urlParams = new URL(document.location).searchParams;
  const mode = urlParams.get("mode");
  let configID = urlParams.get("config");


  let componentProps = {};

  console.info("layout before parsing: " + layout);
  const jsxLayout = parseLayout(layout);
  console.info("layout after parsing: ", jsxLayout);

  const piralAPI = createStandardApi();

  const instance = createInstance({
    state: {
      components: jsxLayout,
      errorComponents: errors,
    },
    plugins: [piralAPI],
    requestPilets: convertComponents(data.components, data.mappings)
  });

  if (dataMgr) {
    instance.root.setData("dataMgr", dataMgr);
    for (var region in data.components) {
      const cmp = data.components[region];
      //TODO: not sure yet how to deal with mutliple of the same type
      instance.root.setData(cmp.id, cmp.uniqueID);
    }
  }

  // propagate all props for components into piral data so they are accessible by the components for creation
  Object.keys(data.components).forEach((cmpID) => {
    const cmpConfig = data.components[cmpID];
    const propVal = cmpConfig?.metaData?.data;
    instance.root.setData("props_" + cmpConfig.uniqueID, propVal);
  });
  instance.root.setData("configid", configID);
  instance.root.setData("mode", mode);
  instance.root.setData("subdomain", getSubdomain());
  instance.root.setData("urlParams", urlParams);

  const eventMgr = new EventEmitter();
  instance.root.setData("eventMgr", eventMgr);
  instance.root.setData("functionMgr", functionMgr);


  instance.root.setData("reactWrapperComp", ExtensionComponent);
  instance.root.setData("reactProvider", Provider);
  instance.root.setData("monitoring", monitoring);
  console.log(instance)

  return <Piral instance={instance} />;
}



function convertComponents(components, mappings) {
  return async function () {
    return new Promise(function (resolve, reject) { resolve() }).then(() => {
      const convertedComponents = [];
      for (var region in components) {
        const cmp = components[region];
        convertedComponents.push({
          name: cmp.uniqueID,
          version: "1.0.0",
          link: cmp.url,
          spec: "v2"
        })
      }
      if (!isEmpty(mappings)) {
        convertedComponents.push(...mappings.map(item => {
          return {
            name: item.id,
            version: "1.0.0",
            link: item.url,
            spec: "v2"
          }
        }))
      }
      return convertedComponents;
    })
  };
}